<template>
    <CRow>
        <CCol col="12" xl="12">
          <CCard>
            <CCardHeader>
              <CRow>
                  <CCol md="5">
                        <h4>Referral Image</h4>
                        <!-- <p style="font-size:12px;">Background Image are only available in Pay Now to Moolahgo eWallet and Pay Now to Bank screens</p>   -->
                  </CCol>
                  <CCol md="5">
                       <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
                  </CCol>
                  <CCol md="2">
                       <!-- <b-button variant="primary" to="/refimage/create" >New Image</b-button> -->
                  </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
                <CRow>
                      <CCol col="12" class="text-left">
                        <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                      </CCol>
                </CRow>
                <CRow>
                      <CCol col="12" class="text-center">
                        <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                      </CCol>
                </CRow>

              <div class="table-responsive table">
                <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">

                  <template #cell(index)="{ item }">
                    {{ items.indexOf(item) + 1 }}
                  </template>

                  <template v-slot:cell(actions)="{ item }">
                    <b-button variant="info" :to='"/refimage/create/" + item.name' >Upload</b-button>
                  </template>

                </b-table>
              </div>

              <b-row>
                <b-col sm="3">
                   <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
                </b-col>
                <b-col sm="9">
                   <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                   </b-form-select>
                </b-col>
              </b-row>

            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </template>

    <script>

     export default {
        name: "referralimage",
        data() {
          return {
            items:[
              { name: 'SIGNUP_REF', dimension : '500 x 300'},
              { name: 'SIGNUP_NO_REF', dimension : '500 x 291'},
              { name: 'REFERRAL',dimension : '1000 x 406'},
              { name: 'QR_LOGO', dimension : '300 x 300'},
            ],
            fields: [
              { key: 'index',label : '#'},
              { key: 'name',label : 'Name',sortable: true},
              { key: 'dimension',label : 'Dimensions (Width x Height) Pixels',sortable: true},
              { key: 'actions',label: 'Actions'}
            ],
            currentPage: 1,
            perPage: 10,
            filter: null,
            spinner:false,
            seen:false,
            msg :'',
            color:''
          };
        },
        created () {
        },
        methods: {
        }
     };
    </script>